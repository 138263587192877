<template>
  <v-layout width="100%">
    <v-app-bar app height="124" class="align-start elevation-1">
      <v-col cols="12" class="py-0">
        <v-row dense align="center">
          <v-col cols="auto" class="d-flex d-lg-none pa-0">
            <!--Platzhalter für mobile Menu-->
            <div style="width: 25px"></div>
          </v-col>
          <v-col cols="auto">
            <v-btn icon x-large @click="$router.go(-1)">
              <v-icon x-large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-toolbar-title>{{ dataset.Terminanfragekategorie }} </v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" v-if="dataset.ist_offen === 1">
            <v-btn color="red lighten-2" :to="linkEditDataset">
              <v-icon class="grey--text text--lighten-4">mdi-delete</v-icon>
              <span class="ml-1 grey--text text--lighten-4 d-none d-lg-flex">Verwerfen</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-app-bar>

    <v-container fluid class="px-0">
      <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0">
        <!--Hauptdaten-->
        <v-row dense class="align-center justify-center pb-5">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense>
                  <v-col cols="12">
                    <v-toolbar-title>
                      {{ dataset.Terminanfragekategorie }}
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense>
                  <v-col cols="12" class="pb-3">
                    <v-row dense>
                      <v-col cols="12">
                        <v-row dense>
                          <v-col cols="12" class="text-no-wrap">
                            <v-row dense v-if="dataset.Name">
                              <v-col cols="5" xl="3" lg="4" class="font-weight-bold">
                                Kundenname
                              </v-col>
                              <v-col cols="7">
                                {{ dataset.Name }}
                              </v-col>
                              <v-spacer></v-spacer>
                            </v-row>
                            <v-row dense v-if="dataset.Email">
                              <v-col cols="5" xl="3" lg="4" class="font-weight-bold">
                                Email
                              </v-col>
                              <v-col cols="7">
                                {{ dataset.Email }}
                              </v-col>
                              <v-spacer></v-spacer>
                            </v-row>
                            <v-row dense v-if="dataset.Telefon">
                              <v-col cols="5" xl="3" lg="4" class="font-weight-bold">
                                Telefon
                              </v-col>
                              <v-col cols="7">
                                {{ dataset.Telefon }}
                              </v-col>
                              <v-spacer></v-spacer>
                            </v-row>
                            <v-row dense v-if="dataset.Hochzeitstermin">
                              <v-col cols="5" xl="3" lg="4" class="font-weight-bold">
                                Hochzeitstermin
                              </v-col>
                              <v-col cols="7">
                                <span>{{
                                  new Date(dataset.Hochzeitstermin).toLocaleDateString("de-DE", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                }}</span>
                              </v-col>
                              <v-spacer></v-spacer>
                            </v-row>
                            <v-row dense v-if="dataset.Anlass">
                              <v-col cols="5" xl="3" lg="4" class="font-weight-bold">
                                Anlass
                              </v-col>
                              <v-col cols="7">
                                {{ dataset.Anlass }}
                              </v-col>
                              <v-spacer></v-spacer>
                            </v-row>
                            <v-row dense v-if="dataset.Rolle">
                              <v-col cols="5" xl="3" lg="4" class="font-weight-bold">
                                Rolle
                              </v-col>
                              <v-col cols="7">
                                {{ dataset.Rolle }}
                              </v-col>
                              <v-spacer></v-spacer>
                            </v-row>
                            <v-row dense v-if="dataset.Konfektionsgröße">
                              <v-col cols="5" xl="3" lg="4" class="font-weight-bold">
                                Konfektionsgröße
                              </v-col>
                              <v-col cols="7">
                                {{ dataset.Konfektionsgröße }}
                              </v-col>
                              <v-spacer></v-spacer>
                            </v-row>
                            <v-row dense v-if="dataset.Begleitpersonen">
                              <v-col cols="5" xl="3" lg="4" class="font-weight-bold">
                                Begleitpersonen
                              </v-col>
                              <v-col cols="7">
                                {{ dataset.Begleitpersonen }}
                              </v-col>
                              <v-spacer></v-spacer>
                            </v-row>
                            <v-row dense v-if="dataset.Nachricht" class="text-wrap">
                              <v-col cols="5" xl="3" lg="4" class="font-weight-bold">
                                Nachricht
                              </v-col>
                              <v-col cols="7" xl="9" lg="8">
                                {{ dataset.Nachricht }}
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
        <!--Artikeleigenschaften und Beschreibung-->
        <v-row dense class="align-center justify-center">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-toolbar-title class="flex-shrink-1">
                  Termine
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined color="green lighten-2">
                  <v-icon>mdi-calendar-plus</v-icon>
                  <span class="ml-1"> anderen Termin festlegen</span>
                </v-btn>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense>
                  <v-col cols="12" class="pb-3">
                    <v-row align="start" justify="center">
                      <v-col v-if="dataset.Wunschdatum" cols="12" xl="6" lg="6" md="6">
                        <v-card tile>
                          <v-card-title class="font-weight-bold">
                            Wunschtermin
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-row dense>
                              <v-col cols="auto" class="mx-auto text--primary">
                                <span>
                                  {{
                                    new Date(dataset.Wunschdatum).toLocaleDateString("de-DE", {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    })
                                  }}</span
                                ><span class="text-no-wrap" v-if="dataset.Wunschzeit">{{ " um " + dataset.Wunschzeit + " Uhr" }}</span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="auto" class="mx-auto">
                                <v-btn dark color="green lighten-2">
                                  <v-icon>mdi-calendar-plus</v-icon>
                                  <span class="ml-1"> als Termin festlegen</span>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-row dense>
                              <v-btn block tile text @click="wunschkalender = !wunschkalender">
                                <span v-if="!wunschkalender" class="subtitle-2">Zeige Tageskalender</span>
                                <span v-if="wunschkalender" class="subtitle-2">Verstecke Tageskalender</span>
                                <v-spacer></v-spacer><v-icon v-if="!wunschkalender" right>mdi-chevron-down</v-icon>
                                <v-icon v-if="wunschkalender" right>mdi-chevron-up</v-icon>
                              </v-btn>
                            </v-row>
                          </v-card-actions>
                          <v-row dense v-show="wunschkalender">
                            <v-col cols="12" class="pa-3">
                              <v-calendar
                                style="width: 100%;"
                                locale="de"
                                type="custom-daily"
                                :start="dataset.Wunschdatum"
                                first-time="08:00"
                                interval-count="6"
                                interval-minutes="120"
                                interval-height="50"
                              >
                                <template v-slot:day-header>
                                  <span class="d-block text-center">
                                    {{
                                      new Date(dataset.Wunschdatum).toLocaleDateString("de-DE", {
                                        year: "numeric",
                                        month: "long",
                                      })
                                    }}
                                  </span>
                                </template>
                              </v-calendar>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                      <v-col v-if="dataset.Alternativdatum" cols="12" xl="6" lg="6" md="6">
                        <v-card tile>
                          <v-card-title class="font-weight-bold">
                            Alternativtermin
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-row dense>
                              <v-col cols="auto" class="mx-auto text--primary">
                                <span>
                                  {{
                                    new Date(dataset.Alternativdatum).toLocaleDateString("de-DE", {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    })
                                  }}</span
                                ><span class="text-no-wrap" v-if="dataset.Alternativzeit">{{ " um " + dataset.Alternativzeit + " Uhr" }}</span>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col cols="auto" class="mx-auto">
                                <v-btn dark color="green lighten-2">
                                  <v-icon>mdi-calendar-plus</v-icon>
                                  <span class="ml-1"> als Termin festlegen</span>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-row dense>
                              <v-btn block tile text @click="alternativkalender = !alternativkalender">
                                <span v-if="!alternativkalender" class="subtitle-2">Zeige Tageskalender</span>
                                <span v-if="alternativkalender" class="subtitle-2">Verstecke Tageskalender</span>
                                <v-spacer></v-spacer><v-icon v-if="!alternativkalender" right>mdi-chevron-down</v-icon>
                                <v-icon v-if="alternativkalender" right>mdi-chevron-up</v-icon>
                              </v-btn>
                            </v-row>
                          </v-card-actions>
                          <v-row dense v-show="alternativkalender">
                            <v-col cols="12" class="pa-3">
                              <v-calendar
                                locale="de"
                                type="custom-daily"
                                :start="dataset.Alternativdatum"
                                first-time="08:00"
                                interval-count="6"
                                interval-minutes="120"
                                interval-height="50"
                              >
                                <template v-slot:day-header>
                                  <span class="d-block text-center">
                                    {{
                                      new Date(dataset.Alternativdatum).toLocaleDateString("de-DE", {
                                        year: "numeric",
                                        month: "long",
                                      })
                                    }}
                                  </span>
                                </template>
                              </v-calendar>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  data: () => ({
    dataset: {},
    wunschkalender: false,
    alternativkalender: false,
  }),

  computed: {
    linkEditDataset() {
      if (this.$route.params.id > 0) {
        return `/wws/masterdata/articles/dataset/edit/${this.$route.params.id}`;
      }
    },
    linkShowManufacturer() {
      if (this.dataset.Hersteller) {
        return `/wws/masterdata/manufacturers/dataset/show/${this.dataset.Hersteller_ID}`;
      }
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/appointments/requests/dataset/show/${this.$route.params.id}`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }
    },
  },
};
</script>
